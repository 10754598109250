import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
import {
	mapActions
} from 'vuex'
import Moment from 'moment'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	data() {
		return {
			queryForm: {
				date: [
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
				]
			},
			tableData: [],
			namespace: '',
			fetchOnCreated: false,
			q: {
				startDate: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
				endDate: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
			},
		}
	},
	created() {
		const {
			startDate,
			endDate
		} = this.$route.query
		if (startDate && endDate && /\d{4}-\d{2}-\d{2}/.test(startDate) && /\d{4}-\d{2}-\d{2}/.test(endDate)) {
			this.queryForm.date = [startDate, endDate]
			this.q.startDate = startDate
			this.q.endDate = endDate
		}
		this.searchEvent(false)
	},
	methods: {
		buildQueryForm(queryForm) {
			let query = {};
			for (let key in queryForm) {
				if (queryForm.hasOwnProperty(key) && queryForm[key].length > 0) {
					if (key === 'date') {
						const [startDate, endDate] = queryForm[key]
						query['startDate'] = startDate
						query['endDate'] = endDate
					} 
				}
			}
			return query;
		},
		buildSearch(query) {
			let search = []
			for (let key in query) {
				if (Array.isArray(query[key])) {
					search.push(`${key}=${query[key].join(',')}`)
				} else {
					search.push(`${key}=${query[key]}`)
				}
			}
			return search.join('&')
		},
		searchEvent(showErr=true) {
			this.q = this.buildQueryForm(this.queryForm)
			const search = this.buildSearch(this.q)
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.getList()
		},
		handleRawData(content) {
			let offerName = null
			let executeAt = null
			let _item = null
			let rowIndex = -1
			const tableData = []
			for (let i = 0; i < content.length; i++) {
				let item = content[i]
				if (_item === null || offerName !== item.offerName || executeAt !== item.executeAt) {
					_item = item
					offerName = _item.offerName
					executeAt = _item.executeAt
					rowIndex++
				}
				item.rowIndex = rowIndex
				if (!_item.ids) {
					_item.ids = [{...item}]
				} else {
					_item.ids.push({...item})
				}
				tableData.push(item)
			}
			this.tableData = tableData
		},
		async getListUrl() {
			if (this.namespace === '') return
			const trigger = this.trigger
			return this.$store.dispatch(`${this.namespace}/fetchJSCXListTermAdjustPlan`, {
				params: {
					...this.q,
				},
				trigger
			})
		},
		downloadTask() {
			const q = this.buildQueryForm(this.queryForm)
			this.$store.commit('download-manager/addTask', {
				action: `${this.namespace}/fetchJSCXListTermAdjustPlan`,
				payload: {...q},
				title: `JSCX-Offers(${q.startDate}~${q.endDate})`,
				dispatch: this.$store.dispatch,
				requestHandler() {
					const params = Array.from(arguments).reduce((lhs, rhs) => {
						return {...lhs, ...rhs}
					}, {})
					return {params}
				},
				responseHandler:(content) => {
					return {
						content,
						totalPages: 1
					}
				}
			})
		}
	}
}