<template>
	<el-table class="jscx-term-adjust-table" height="auto" ref="multipleTable" :data="tableData" @select="pinSelect"
		@sort-change="$emit('sortChange', $event)" size="mini" border @selection-change="handleSelectionChange"
		:header-row-style="headerRowStyle" :cell-style="cellStyle" :row-style="rowStyle"
		:span-method="objectSpanMethod" :row-class-name="rowClass">
		<el-table-column v-for="tableColumn, index in tableColumns" :label="tableColumn.label" :prop="tableColumn.prop"
			:min-width="tableColumn.width || 100" align="center" :sortable="tableColumn.sortable"
			:formatter="cellFormatter">
			<template slot-scope="scope">
				<template v-if="scope.column.property==='status'">
					<div v-if="scope.row.ids.every(v => v.replaceTerm === null)" style="color:#909399;">无需调整</div>
					<el-button v-else-if="scope.row.ids.some(v => !v.status)" type="text" @click="clickRow(scope.row)" size="mini">确认替换</el-button>
					<div v-else class="text-success">已确认</div>
				</template>
				<template v-else-if="scope.column.property==='plan'">
					<div v-if="scope.row.reason !== null">{{scope.row.plan}}, {{scope.row.reason}}</div>
					<div v-else>{{scope.row.plan}}</div>
				</template>
				<template v-else>
					{{cellFormatter(scope.row, scope.column, scope.row[scope.column.property], scope.$index)}}
				</template>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import {
		mapGetters
	} from 'vuex'
	import Moment from 'moment'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			},
			tableColumns: {
				type: Array,
				default: []
			},
			confirmJSCXTermAdjustPlan: {
				type: Function,
				default: null
			}
		},
		methods: {
			clickRow(row) {
				this.confirmJSCXTermAdjustPlan && this.confirmJSCXTermAdjustPlan(row)
			},
			doLayout() {
				this.$refs.multipleTable.doLayout()
			},
			cellFormatter(row, column, cellValue, index) {
				if (/executeAt/i.test(column.property)) {
					return Moment(Number(cellValue) * 1000).format('YYYY-MM-DD HH:mm:ss')
				} else if (/offerL3dProfitRate/i.test(column.property)) {
					return cellValue === null ? '-' : ((Number(cellValue) * 100).toFixed(2) + '%')
				} else if (/(epc)|(offerL3dProfit)|(offerL3dCost)|(offerL3dRevenue)/i.test(column.property)) {
					return cellValue === null ? '-' : Number(cellValue).toFixed(2)
				} else if (/(conversion)/i.test(column.property)) {
					return cellValue === null ? '-' : Number(cellValue).toFixed(0)
				} else if (/direction/i.test(column.property)) {
					return cellValue || 'Others'
				} else if (/replaceHours/i.test(column.property)) {
					if (cellValue === null) return '-'
					const hour = cellValue % 24
					const tail = hour > 0 ? `${hour}h` : ''
					const day = Math.floor(cellValue / 24)
					const lead = day > 0 ? `${day}d` : ''
					return lead + tail
				}
				return cellValue || '-'
			},
			headerRowStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				return {
					'font-size': '10px',
					'text-transform': 'capitalize'
				}
			},
			rowClass({row, rowIndex}) {
				return Number(row.rowIndex) % 2 === 0 ? 'jscx-term-row-a': 'jscx-term-row-b'
			},
			cellStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				return {}
			},
			rowStyle({
				row,
				rowIndex
			}) {
				return {
					'font-size': '10px',
					'color': 'black'
				}
			},
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (['status', 'offerName', 'direction', 'offerL3dAvgEpc', 'offerL3dAvgRealEpc', 'executeAt', 'offerL3dCost', 'offerL3dProfit', 'offerL3dProfitRate', 'offerL3dRevenue'].includes(column.property)) {
					return row.ids !== undefined ? {rowspan: row.ids.length, colspan: 1} : {rowspan:0, colspan: 0}
				}
			}
		}
	}
</script>

<style>
	.jscx-term-adjust-table.el-table .el-table__body tr:hover>td.el-table__cell {
		background-color: unset;
	}
	.jscx-term-adjust-table.el-table tr.jscx-term-row-a {
		background-color: rgba(232, 245, 233, 0.5);
	}
	.jscx-term-adjust-table.el-table tr.jscx-term-row-b {
		background-color: rgba(227, 242, 253, 0.5);
	}
	.jscx-term-adjust-table.el-table, .jscx-term-adjust-table.el-table td, .jscx-term-adjust-table.el-table th {
	  border-color: #a0a0a0; /* 更深的边框颜色，使之在背景色下更明显 */
	}
</style>